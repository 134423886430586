import { SEO } from '@app/components/legacy/mol.seo/seo.component'
import { ChapterNavigation } from '@app/components/mol.chapter-navigation/chapter-navigation.component'
import {
  ScrollInfo,
  TableOfContents
} from '@app/components/mol.table-of-contents/table-of-contents.component'
import TitleWithBreadcrumbRow from '@app/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import { parseToDate } from '@app/utils/date-parser/date-parser'
import { PageProps } from '@app/utils/local-types'
import { getClientAndDoctorUrl, normalizeUrl } from 'utils/url'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { CosmicjsInstitucionalMetadataConteudos, Query } from '@root/src/data/graphql/graphql-types'
import { Col, Grid, H2, H3, Row, Separator } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { graphql } from 'gatsby'
import React from 'react'
import { ChapterIndex } from '../diagnostic-guide/diagnostic-manual/diagnostic-manual-detail'

export interface RelatedPagesProps {
  title: string
  url: string
}

export interface AboutDetailTemplateProps {
  slug: string
  relatedPages: RelatedPagesProps[]
}

const AboutDetailTemplate: React.FunctionComponent<PageProps<
  AboutDetailTemplateProps,
  Query
>> = props => {
  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)
  const data = props.data.cosmicjsInstitucional
  const currentPath = `${props.location.pathname}${props.location.search}`
  const currentPageUrl = normalizeUrl(`${props.data.site.siteMetadata.siteUrl}${currentPath}`)

  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[`/${props.pageContext.slug}`] = data.title

  const navIndex: ChapterIndex[] = props.pageContext.relatedPages

  const scrollInfo: ScrollInfo[] = data.metadata.conteudos.map((section, index) => ({
    id: getSectionId(section, index),
    text: section.titulo
  }))
  const shouldShowTableOfContents = scrollInfo.length > 3

  return (
    <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
      <IndexLayout location={props.location}>
        <>
          <SEO
            socialMedia={{
              canonicalUrl: currentPageUrl,
              title: data.metadata.seo.titulo,
              image: data.metadata.seo.imagem.url,
              imageAlt: data.metadata.seo.imagemAlternativa,
              description: data.metadata.seo.descricao,
              article: {
                author: ['Fleury Medicina e Saúde'],
                modified_time: parseToDate(data.modified_at),
                published_time: parseToDate(data.published_at),
                section: 'Sobre fleury',
                tag: [] // data.metadata.tags
              }
            }}
          />
          <Grid>
            <TitleWithBreadcrumbRow
              title={data.title}
              addtionalDictionary={breadcrumbAdditionalDictionary}
            />
            <Row mb>
              <Col xs={12} md={7}>
                <div>
                  <LazyLoadImage
                    src={data.metadata.imagem.imgix_url}
                    fitWidth
                    aspectRatio={360 / 1440}
                    alt={`banner-${data.slug}`}
                    sizes={'80vw'}
                  />
                </div>
                <Separator />
                {shouldShowTableOfContents && (
                  <>
                    <H2>Tópicos presentes nesta página: </H2>
                    <TableOfContents scrollInfo={scrollInfo} />
                  </>
                )}
                {data.metadata.conteudos.map((content, index) => (
                  <React.Fragment key={index}>
                    <H3 id={getSectionId(content, index)}>{content.titulo}</H3>
                    <div dangerouslySetInnerHTML={{ __html: content.texto }} />
                  </React.Fragment>
                ))}
              </Col>
              <Col xs={12} mdOffset={1} md={4}>
                <Row>
                  <Col xs={12}>
                    {/* // TODO: COSMIC */}
                    <H2 cell>Saiba mais sobre nós</H2>
                    <ChapterNavigation
                      chapterIndex={navIndex}
                      currentPath={props.location.pathname}
                      hideNav
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <LargeSeparatorRow />
          </Grid>
        </>
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

const getSectionId = (_section: CosmicjsInstitucionalMetadataConteudos, index: number) => {
  return `${index}`
}

export default AboutDetailTemplate

export const query = graphql`
  query AboutDetailBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cosmicjsInstitucional(slug: { eq: $slug }) {
      id
      title
      slug
      modified_at
      published_at
      metadata {
        imagem {
          url
          imgix_url
        }
        conteudos {
          titulo
          texto
        }
        seo {
          titulo
          descricao
          imagem {
            url
          }
          imagemAlternativa
        }
      }
    }
  }
`
