import { hasDocument, hasWindow } from 'utils/browser'

// https://stackoverflow.com/a/56461927/3670829
export const scrollToId = (id: string, offset: number) => {
  if (!hasWindow() || !hasDocument()) {
    return
  }
  const scrollToEl = document.getElementById(id)
  const buffer = 16
  const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - offset - buffer
  window.scroll({ top: topOfElement, behavior: 'smooth' })
}
