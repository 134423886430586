import { H4Style, Color, Spacing, TransitionDuration } from 'atomic'
import styled from 'styled-components'

export const ChapterNavigationStyled = styled.ol`
  margin: 0;
  padding: 0;

  list-style: none;
  counter-reset: item;
`

export interface ChapterNavigationItemStyledProps {
  active: boolean
  hideNav?: boolean
}

export const ChapterNavigationItemStyled = styled.li<ChapterNavigationItemStyledProps>`
  ${H4Style}
  color: ${props => (props.active ? props.theme.color.primary : Color.GrayXDark)};
  display: flex;
  cursor: pointer;
  margin: 0;
  padding: ${Spacing.Small};
  transition: all ${TransitionDuration};

  :hover {
    background: ${Color.GrayLight};
  }


    ${props =>
      props.hideNav
        ? ''
        : `
  :before {
    display: inline-block;
    flex: 0;
    margin-right: ${Spacing.Small};
    min-width: 19px;
    content: counter(item) '. ';
    counter-increment: item;
    color: ${props.active ? props.theme.color.primary : Color.GrayXDark};
  }
    `};
`

export const ChapterNavigationItemTextStyled = styled.span`
  display: inline-block;
`
