import { ChapterIndex } from '@root/src/modules/diagnostic-guide/diagnostic-manual/diagnostic-manual-detail'
import { Link } from 'gatsby'
import * as React from 'react'
import { isExternalUrl, normalizeUrl } from 'utils/url'
import {
  ChapterNavigationItemStyled,
  ChapterNavigationItemTextStyled,
  ChapterNavigationStyled
} from './chapter-navigation.component.style'

interface ChapterNavigationProps {
  currentPath: string
  hideNav?: boolean
  chapterIndex: ChapterIndex[]
}

export const ChapterNavigation: React.FunctionComponent<ChapterNavigationProps> = props => {
  return (
    <ChapterNavigationStyled>
      {props.chapterIndex &&
        props.chapterIndex.map(index => {
          const active = normalizeUrl(`${props.currentPath}/`) === normalizeUrl(`${index.url}/`)
          const innerContent = (
            <ChapterNavigationItemStyled active={active} hideNav={props.hideNav}>
              <ChapterNavigationItemTextStyled>{index.title}</ChapterNavigationItemTextStyled>
            </ChapterNavigationItemStyled>
          )
          return isExternalUrl(index.url) ? (
            <a target="_blank" href={index.url}>
              {innerContent}
            </a>
          ) : (
            <Link key={index.url} to={index.url}>
              {innerContent}
            </Link>
          )
        })}
    </ChapterNavigationStyled>
  )
}
