import { FaIcon, HeaderHeight } from 'atomic'
import { isDesktopScreenSize } from 'utils/browser'
import { scrollToId } from '@root/src/utils/scroll'
import * as React from 'react'
import {
  TableOfContentsItemStyled,
  TableOfContentsStyled
} from './table-of-contents.component.style'

export interface ScrollInfo {
  id: string
  text: string
  tag?: string
}

export interface TableOfContentsProps {
  scrollInfo: ScrollInfo[]
}

export const TableOfContents: React.FunctionComponent<TableOfContentsProps> = props => {
  return (
    <TableOfContentsStyled>
      {props.scrollInfo.map(info => {
        const handleClick = React.useCallback(() => {
          const headerHeight = isDesktopScreenSize() ? HeaderHeight.Desk : HeaderHeight.Mobile
          scrollToId(info.id, headerHeight)
        }, [info.id])

        return info.text ? (
          <TableOfContentsItemStyled key={info.id} onClick={handleClick}>
            {info.text} &nbsp; <FaIcon.ArrowRight />
          </TableOfContentsItemStyled>
        ) : null
      })}
    </TableOfContentsStyled>
  )
}
